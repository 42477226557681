<template>
  <a-spin :spinning="loading">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <!-- <h2 class="mr-3" v-if="!editable">Detail Asset Baru</h2>
        <h2 class="mr-3" v-else>Update Asset Baru</h2> -->
        <!-- <h2 class="mr-3">{{ editable ? 'Update Asset Baru' : 'Detail Asset Baru' }}</h2> -->
        <h2 class="mr-3">{{ role !== 'dcOwner' ? 'Update' : 'Detail' }} Asset Baru</h2>
      </div>
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <a-button @click="changeEdittable" v-if="role !== 'dcOwner'">
            <span v-if="!editable">
              <FormOutlined class="mr-2" />Edit
            </span>
            <span v-else>
              <EyeOutlined class="mr-2" />View
            </span>
          </a-button>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Customer & Location</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Customer</p>
                  <a-select placeholder="Pilih Customer" class="w-100" v-model:value="selectedCustomer"
                    :disabled="!editable" @change="handleChangeCustomer">
                    <a-select-option v-for="(item, index) in dataCustomers" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Location</p>
                  <a-select placeholder="Pilih Lokasi" class="w-100" v-model:value="selectedLocation"
                    @change="handleChangeLocation" :disabled="!selectedCustomer || !editable">
                    <a-select-option v-for="(item, index) in dataLocation" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Room</p>
                  <a-select placeholder="Pilih Room" class="w-100" v-model:value="selectedRoom"
                    @change="handleChangeRoom" :disabled="!selectedLocation || !editable">
                    <a-select-option v-for="(item, index) in dataRoom" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Asset Information</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Category</p>
                  <a-select placeholder="Pilih Asset Category" class="w-100" v-model:value="selectedCategoriesAssets"
                    @change="handleChangeCategoriesAssets" :disabled="!editable">
                    <a-select-option v-for="(item, index) in dataCategoriesAssets" :key="index" :value="item.id">{{
                      item.name }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Name</p>
                  <a-input placeholder="Masukkan Nama Asset" v-model:value="dataPayload.name"
                    :disabled="!editable"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }"> </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Brand</p>
                  <a-select placeholder="Pilih Manufacturer" class="w-100" v-model:value="dataPayload.manufacturerId"
                    :disabled="!editable">
                    <a-select-option v-for="(item, index) in dataManufatures" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Model</p>
                <a-input placeholder="Masukkan Model" v-model:value="dataPayload.modelAsset"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Product No</p>
                <a-input placeholder="Masukkan Product No" v-model:value="dataPayload.productNumber"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Serial No</p>
                <a-input placeholder="Masukkan Serial No" v-model:value="dataPayload.serialNumber"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Service Tag</p>
                <a-input placeholder="Masukkan Service Tag" v-model:value="dataPayload.serviceTag"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Asset Tag</p>
                <a-input placeholder="Masukkan Asset Tag" v-model:value="dataPayload.assetTag"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="$route.path.split('/').at(1) !== 'software'">
                <p style="text-align: left;" class="text-label">Rack No</p>
                <a-input placeholder="Masukkan Rack No" v-model:value="dataPayload.rackNumber"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="$route.path.split('/').at(1) !== 'software'">
                <p style="text-align: left;" class="text-label">U No</p>
                <a-input placeholder="Masukkan U No" v-model:value="dataPayload.uNumber"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="$route.path.split('/').at(1) !== 'software'">
                <p style="text-align: left;" class="text-label">U Size</p>
                <a-input placeholder="Masukkan U Size" v-model:value="dataPayload.uSize"
                  :disabled="!editable"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-subtitle">Procurement Information</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Tanggal Pembelian</p>

                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.procurements.purchaseDate" :disabled="!editable"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :span="12">
                <p class="text-label">Nomor Pembelian</p>
                <a-input placeholder="Masukkan Nomor Pembelian" v-model:value="dataPayload.procurements.No"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 4 }">
                <p class="text-label">Value</p>
                <a-input placeholder="Masukkan Value" suffix="IDR" v-model:value="dataPayload.procurements.value"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi</p>
                <a-input placeholder="Masukkan Garansi" suffix="Month(s)"
                  v-model:value="dataPayload.procurements.warranty" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Mulai</p>
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.procurements.warrantyStart"
                  :disabled="!editable || !dataPayload.procurements.warranty"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Berakhir</p>
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.procurements.warrantyEnd" disabled></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :span="12">
                <p class="text-label">Vendor</p>
                <a-select placeholder="Pilih Vendor" class="w-100" v-model:value="dataPayload.procurements.vendorId"
                  @change="handleChangeProcurementVendor" :disabled="!editable">
                  <a-select-option v-for="(item, index) in dataVendors" :key="index" :value="item.id">{{ item.name
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Email</p>
                <a-input disabled placeholder="Masukkan Email" v-model:value="dataPayload.procurements.email"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input disabled placeholder="Masukkan Nomor Telepon"
                  v-model:value="dataPayload.procurements.mobileNumber"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <!-- dsni -->
        <a-col :span="24" v-if="$route.path.split('/').at(1) !== 'passive'">
          <div>
            <h1 class="text-subtitle">Maintenance Information</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <a-select v-model:value="selectedMaintenanceInformation" class="w-100 mt-2 mb-2">
                  <a-select-option v-for="(item, index) in dataPayload.maintenances" :key="index"
                    :value="index">Maintenance
                    Information
                    {{
                      index + 1
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 4 }">
                <a-button @click="addMaintenanceInformation" type="primary" class="mt-2"
                  :disabled="editable ? false : true">Tambah Data
                  Maintenance</a-button>
              </a-col>
            </a-row>
          </div>

          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Tanggal Kontrak</p>
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].purchaseDate"
                  :disabled="!editable"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :span="12">
                <p class="text-label">Nomor Kontrak</p>
                <a-input placeholder="Masukkan Nomor Kontrak"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].No"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 4 }">
                <p class="text-label">Value</p>
                <a-input placeholder="Masukkan Value" suffix="IDR"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].value"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi</p>
                <a-input placeholder="Masukkan Garansi" suffix="Month(s)"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].warranty"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Mulai</p>
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].warrantyStart"
                  :disabled="!editable"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Garansi Berakhir</p>
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].warrantyEnd"
                  :disabled="!editable"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :span="12">
                <p class="text-label">Vendor</p>
                <a-select placeholder="Pilih Vendor" class="w-100"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].vendorId"
                  @change="handleChangeMaintenancesVendor" :disabled="!editable">
                  <a-select-option v-for="(item, index) in dataVendors" :key="index" :value="item.id">{{ item.name
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Email</p>
                <a-input disabled placeholder="Masukkan Email"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].email"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input disabled placeholder="Masukkan Nomor Telepon"
                  v-model:value="dataPayload.maintenances[selectedMaintenanceInformation].mobileNumber"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24" v-if="selectedCategoriesAssets && selectedCategoriesAssets !== 16">
          <div>
            <h1 class="text-title">Technical Specification</h1>
          </div>
          <div v-if="selectedCategoriesAssets == 19">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Name</p>
                <a-input placeholder="Masukkan Software Name" v-model:value="dataPayload.softwareName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Version</p>
                <a-input placeholder="Masukkan Software Version" v-model:value="dataPayload.softwareVersion"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Access Type</p>
                <a-select placeholder="Pilih Access Type" class="w-100" v-model:value="dataPayload.accessType"
                  :disabled="!editable">
                  <a-select-option value="Manual">Manual</a-select-option>
                  <a-select-option value="Electric Card">Electric Card</a-select-option>
                  <a-select-option value="Electric Finger Print">Electric Finger Print</a-select-option>
                  <a-select-option value="Other">Other</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 18">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Name</p>
                <a-input placeholder="Masukkan Software Name" v-model:value="dataPayload.softwareName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Version</p>
                <a-input placeholder="Masukkan Software Version" v-model:value="dataPayload.softwareVersion"
                  :disabled="!editable"></a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 13 || selectedCategoriesAssets == 14">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Air Flow</p>
                <a-select placeholder="Pilih Air Flow" class="w-100" v-model:value="dataPayload.airFlow"
                  :disabled="!editable">
                  <!-- "Down Flow", "Up Flow", "Horizontal" -->
                  <a-select-option value="Down Flow">Down Flow</a-select-option>
                  <a-select-option value="Up Flow">Up Flow</a-select-option>
                  <a-select-option value="Horizontal">Horizontal</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Cooling Design</p>
                <a-select placeholder="Pilih Cooling Design" class="w-100" v-model:value="dataPayload.coolingDesign"
                  :disabled="!editable">
                  <!-- "Room Based", "Row Based", "Rack Based" -->
                  <a-select-option value="Room Based">Room Based</a-select-option>
                  <a-select-option value="Row Based">Row Based</a-select-option>
                  <a-select-option value="Rack Based">Rack Based</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Cooling Type</p>
                <a-select placeholder="Pilih Cooling Type" class="w-100" v-model:value="dataPayload.coolingType"
                  :disabled="!editable">
                  <!-- "Air Cooling", "Water Cooling" -->
                  <a-select-option value="Air Cooling">Air Cooling</a-select-option>
                  <a-select-option value="Water Cooling">Up Flow</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Cooling Capacity</p>
                <a-input placeholder="Masukkan Cooling Capacity" suffix="kWh"
                  v-model:value="dataPayload.coolingCapacity" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Input Power</p>
                <a-input placeholder="Masukkan Input Power" suffix="kWh" v-model:value="dataPayload.inputPowerKw"
                  :disabled="!editable"></a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 7">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Panel Type</p>
                <a-select placeholder="Pilih Panel Type" class="w-100" v-model:value="dataPayload.panelType"
                  :disabled="!editable">
                  <!-- "Main Breaker","Main Lug","Sub","Transfer Switches" -->
                  <a-select-option value="Main Breaker">Main Breaker</a-select-option>
                  <a-select-option value="Main Lug">Main Lug</a-select-option>
                  <a-select-option value="Sub">Sub</a-select-option>
                  <a-select-option value="Transfer Switches">Transfer Switches</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Transfer Switch</p>
                <a-select placeholder="Pilih Transfer Switch" class="w-100" v-model:value="dataPayload.transferSwitch"
                  :disabled="!editable">
                  <!-- "Manual", "Semi-Automatic", "Automatic" -->
                  <a-select-option value="Manual">Manual</a-select-option>
                  <a-select-option value="Semi-Automatic">Semi-Automatic</a-select-option>
                  <a-select-option value="Automatic">Automatic</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Phase Type</p>
                <a-select placeholder="Pilih Phase Type" class="w-100" v-model:value="dataPayload.phaseType"
                  :disabled="!editable">
                  <!-- "Air Cooling", "Water Cooling" -->
                  <a-select-option value="1 Phase">1 Phase</a-select-option>
                  <a-select-option value="2 Phase">2 Phase</a-select-option>
                  <a-select-option value="3 Phase">3 Phase</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 20">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Sensor Type</p>
                <a-select placeholder="Pilih Sensor Type" class="w-100" v-model:value="dataPayload.sensorType"
                  :disabled="!editable">
                  <!-- ""Temperature","Humidity","Dry Contact","Smoke Detector"" -->
                  <a-select-option value="Temperature">Temperature</a-select-option>
                  <a-select-option value="Humidity">Humidity</a-select-option>
                  <a-select-option value="Dry Contact">Dry Contact</a-select-option>
                  <a-select-option value="Smoke Detector">Smoke Detector</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Name</p>
                <a-input placeholder="Masukkan Software Name" v-model:value="dataPayload.softwareName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Version</p>
                <a-input placeholder="Masukkan Software Version" v-model:value="dataPayload.softwareVersion"
                  :disabled="!editable"></a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 15">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Name</p>
                <a-input placeholder="Masukkan Software Name" v-model:value="dataPayload.softwareName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Version</p>
                <a-input placeholder="Masukkan Software Version" v-model:value="dataPayload.softwareVersion"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Suppression Type</p>
                <a-select placeholder="Pilih Suppression Type" class="w-100"
                  v-model:value="dataPayload.extenguisherType" :disabled="!editable">
                  <!-- """Water", "Inert Gas", "Chemical""" -->
                  <a-select-option value="Water">Water</a-select-option>
                  <a-select-option value="Inert Gas">Inert Gas</a-select-option>
                  <a-select-option value="Chemical">Chemical</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Fill in Date</p>
                <a-date-picker class="w-100" placeholder="Pilih tanggal" v-model:value="dataPayload.fillDate"
                  :disabled="!editable"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Expired Date</p>
                <a-date-picker class="w-100" placeholder="Pilih tanggal" v-model:value="dataPayload.expireDate"
                  :disabled="!editable"></a-date-picker>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 17">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Name</p>
                <a-input placeholder="Masukkan Software Name" v-model:value="dataPayload.softwareName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Version</p>
                <a-input placeholder="Masukkan Software Version" v-model:value="dataPayload.softwareVersion"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Extinguisher Type</p>
                <a-select placeholder="Pilih Extinguisher Type" class="w-100"
                  v-model:value="dataPayload.extenguisherType" :disabled="!editable">
                  <!-- """Water", "Inert Gas", "Chemical""" -->
                  <a-select-option value="Water">Water</a-select-option>
                  <a-select-option value="Inert Gas">Inert Gas</a-select-option>
                  <a-select-option value="Chemical">Chemical</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Fill in Date</p>
                <a-date-picker class="w-100" placeholder="Pilih tanggal" v-model:value="dataPayload.fillDate"
                  :disabled="!editable"></a-date-picker>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Expired Date</p>
                <a-date-picker class="w-100" placeholder="Pilih tanggal" v-model:value="dataPayload.expireDate"
                  :disabled="!editable"></a-date-picker>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 10">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">kVa</p>
                <a-input placeholder="Masukkan kVa" suffix="kVa" v-model:value="dataPayload.kva"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">kW</p>
                <a-input placeholder="Masukkan kW" suffix="kW" v-model:value="dataPayload.kw"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Machine Type</p>
                <a-select placeholder="Pilih Machine Type" class="w-100" v-model:value="dataPayload.machineType"
                  :disabled="!editable">
                  <!-- "Manual", "Semi-Automatic", "Automatic" -->
                  <a-select-option value="Diesel">Diesel</a-select-option>
                  <a-select-option value="Gasoline">Gasoline</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">System Trigger</p>
                <a-select placeholder="Pilih System Trigger" class="w-100" v-model:value="dataPayload.systemTrigger"
                  :disabled="!editable">
                  <!-- "Manual", "Semi-Automatic", "Automatic" -->
                  <a-select-option value="Manual">Manual</a-select-option>
                  <a-select-option value="Semi-Automatic">Semi-Automatic</a-select-option>
                  <a-select-option value="Automatic">Automatic</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 8">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Model</p>
                <a-select placeholder="Pilih Model" class="w-100" v-model:value="dataPayload.model"
                  :disabled="!editable">
                  <!-- "Tiny", "Small", "Medium", "Large" -->
                  <a-select-option value="Tiny">Tiny</a-select-option>
                  <a-select-option value="Small">Small</a-select-option>
                  <a-select-option value="Medium">Medium</a-select-option>
                  <a-select-option value="Large">Large</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Form Factor</p>
                <a-select placeholder="Pilih Form Factor" class="w-100" v-model:value="dataPayload.formFactor"
                  :disabled="!editable">
                  <!-- "Tower", "Rackmount" -->
                  <a-select-option value="Tower">Tower</a-select-option>
                  <a-select-option value="Rackmount">Rackmount</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Battery</p>
                <a-select placeholder="Pilih Battery" class="w-100" v-model:value="dataPayload.battery"
                  :disabled="!editable">
                  <!-- "Included", "Separated" -->
                  <a-select-option value="Included">Included</a-select-option>
                  <a-select-option value="Separated">Separated</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Height</p>
                <a-input placeholder="Masukkan Height" v-model:value="dataPayload.height"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Width</p>
                <a-input placeholder="Masukkan Width" v-model:value="dataPayload.width" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Depth</p>
                <a-input placeholder="Masukkan Depth" v-model:value="dataPayload.depth" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Weight</p>
                <a-input placeholder="Masukkan Weight" v-model:value="dataPayload.weight"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Backup kVa</p>
                <a-input placeholder="Masukkan Backup kVa" suffix="kVa" v-model:value="dataPayload.backupKva"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Backup kW</p>
                <a-input placeholder="Masukkan Backup kW" suffix="kW" v-model:value="dataPayload.backupKw"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Backup Duration</p>
                <a-input placeholder="Masukkan Backup Duration" v-model:value="dataPayload.backupDuration"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Input kVa</p>
                <a-input placeholder="Masukkan Input kVa" suffix="kVa" v-model:value="dataPayload.inputKva"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Input kW</p>
                <a-input placeholder="Masukkan Input kW" suffix="kW" v-model:value="dataPayload.inputKw"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Output kVa</p>
                <a-input placeholder="Masukkan Output kVa" suffix="kVa" v-model:value="dataPayload.outputKva"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Output kW</p>
                <a-input placeholder="Masukkan Output kW" suffix="kW" v-model:value="dataPayload.outputKw"
                  :disabled="!editable"></a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 11">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Battery Type</p>
                <a-select placeholder="Pilih Battery Type" class="w-100" v-model:value="dataPayload.batteryType"
                  :disabled="!editable">
                  <!-- "Manual", "Semi-Automatic", "Automatic" -->
                  <a-select-option value="VRLA">VRLA</a-select-option>
                  <a-select-option value="Lithium-Ion">Lithium-Ion</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Voltage</p>
                <a-input placeholder="Masukkan Voltage" v-model:value="dataPayload.voltage"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Cells</p>
                <a-input placeholder="Masukkan Cells" v-model:value="dataPayload.cells" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Controller Status</p>
                <a-select placeholder="Pilih Controller Status" class="w-100"
                  v-model:value="dataPayload.controllerStatus" :disabled="!editable">
                  <!-- "Load Shed", "Bost", "Float", "Fault" -->
                  <a-select-option value="Load Shed">Load Shed</a-select-option>
                  <a-select-option value="Bost">Bost</a-select-option>
                  <a-select-option value="Float">Float</a-select-option>
                  <a-select-option value="Fault">Fault</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 22">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 9 }">
                <p class="text-label">Wifi Name</p>
                <a-input placeholder="Masukkan Wifi Name" v-model:value="dataPayload.wifiName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 5 }">
                <p class="text-label">Available Port</p>
                <a-input placeholder="Masukkan Available Port" v-model:value="dataPayload.availablePort"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 5 }">
                <p class="text-label">Working Port</p>
                <a-input placeholder="Masukkan Working Port" v-model:value="dataPayload.workingPort"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 5 }">
                <p class="text-label">Dead Port</p>
                <a-input placeholder="Pilih Dead Port" class="w-100" v-model:value="dataPayload.deadPort"
                  :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">IP Address</p>
                <a-input placeholder="Pilih IP Address" class="w-100" v-model:value="dataPayload.ipAdress"
                  :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Subnet Mask</p>
                <a-input placeholder="Pilih Subnet Mask" class="w-100" v-model:value="dataPayload.subnetMask"
                  :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Gateway</p>
                <a-input placeholder="Pilih Gateway" class="w-100" v-model:value="dataPayload.gateway"
                  :disabled="!editable">
                </a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 23 ||
            selectedCategoriesAssets == 24 ||
            selectedCategoriesAssets == 26
          ">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Total Port</p>
                <a-input placeholder="Masukkan Total Port" v-model:value="dataPayload.totalPort"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Available Port</p>
                <a-input placeholder="Masukkan Available Port" v-model:value="dataPayload.availablePort"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Working Port</p>
                <a-input placeholder="Masukkan Working Port" v-model:value="dataPayload.workingPort"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 6 }">
                <p class="text-label">Dead Port</p>
                <a-input placeholder="Masukkan Dead Port" class="w-100" v-model:value="dataPayload.deadPort"
                  :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">IP Address</p>
                <a-input placeholder="Masukkan IP Address" class="w-100" v-model:value="dataPayload.ipAdress"
                  :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Subnet Mask</p>
                <a-input placeholder="Masukkan Subnet Mask" class="w-100" v-model:value="dataPayload.subnetMask"
                  :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Gateway</p>
                <a-input placeholder="Masukkan Gateway" class="w-100" v-model:value="dataPayload.gateway"
                  :disabled="!editable">
                </a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 25 || selectedCategoriesAssets == 32">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">System Name</p>
                <a-input placeholder="Masukkan System Name" v-model:value="dataPayload.systemName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Host Name</p>
                <a-input placeholder="Masukkan Host Name" v-model:value="dataPayload.hostName"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Domain Role</p>
                <a-input placeholder="Masukkan Domain Role" v-model:value="dataPayload.domainRole"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">DNS</p>
                <a-input placeholder="Masukkan DNS" class="w-100" v-model:value="dataPayload.dns" :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">IP Address</p>
                <a-input placeholder="Masukkan IP Address" class="w-100" v-model:value="dataPayload.ipAdress"
                  :disabled="!editable">
                </a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">OS</p>
                <a-input placeholder="Masukkan OS" class="w-100" v-model:value="dataPayload.os" :disabled="!editable">
                </a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 27">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">UTP Type</p>
                <a-select class="w-100" placeholder="Pilih UTP Type" v-model:value="dataPayload.utpType"
                  :disabled="!editable">
                  <a-select-option v-for="( item, index ) in utpType " :key="index" :value="item">{{
                    item
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Node</p>
                <a-input placeholder="Masukkan Node" v-model:value="dataPayload.node" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Connection</p>
                <a-select class="w-100" placeholder="Pilih Connection" v-model:value="dataPayload.connectionType"
                  :disabled="!editable">
                  <a-select-option v-for="( item, index ) in connectionType " :key="index" :value="item">{{ item
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Patch</p>
                <a-select class="w-100" placeholder="Pilih Patch" v-model:value="dataPayload.patch"
                  :disabled="!editable">
                  <!-- <a-select-option v-for="(item, index) in utpType" :key="index" :value="item">{{item}}</a-select-option> -->
                  <a-select-option value="1.0">1.0</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Cable Management</p>
                <a-select class="w-100" placeholder="Pilih Cable Management" v-model:value="dataPayload.cableManagement"
                  :disabled="!editable">
                  <a-select-option value="yes">
                    Yes
                  </a-select-option>
                  <a-select-option value="no">
                    No
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 28">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Rack Unit</p>
                <a-input placeholder="Masukkan Rack Unit" v-model:value="dataPayload.rackUnit"
                  :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Cross Connection</p>
                <a-select class="w-100" placeholder="Pilih Cross Connection" v-model:value="dataPayload.crossConnection"
                  :disabled="!editable">
                  <a-select-option value="yes">
                    Yes
                  </a-select-option>
                  <a-select-option value="no">
                    No
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 29">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Rack Unit</p>
                <a-input placeholder="Masukkan Rack Unit" v-model:value="dataPayload.rackUnit"
                  :disabled="!editable"></a-input>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 30">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">FO Type</p>
                <a-select class="w-100" placeholder="Pilih UTP Type" v-model:value="dataPayload.foType"
                  :disabled="!editable">
                  <a-select-option v-for="( item, index ) in foType " :key="index" :value="item">{{
                    item
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Node</p>
                <a-input placeholder="Masukkan Node" v-model:value="dataPayload.node" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Core</p>
                <a-input placeholder="Masukkan Core" v-model:value="dataPayload.core" :disabled="!editable"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Connection</p>
                <a-select class="w-100" placeholder="Pilih Connection" v-model:value="dataPayload.connectionType"
                  :disabled="!editable">
                  <a-select-option v-for="( item, index ) in foConnectionType " :key="index" :value="item">{{ item
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Patch</p>
                <a-select class="w-100" placeholder="Pilih Patch" v-model:value="dataPayload.patch"
                  :disabled="!editable">
                  <!-- <a-select-option v-for="(item, index) in utpType" :key="index" :value="item">{{item}}</a-select-option> -->
                  <a-select-option value="1.0">1.0</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Cable Management</p>
                <a-select class="w-100" placeholder="Pilih Cable Management" v-model:value="dataPayload.cableManagement"
                  :disabled="!editable">
                  <a-select-option value="yes">
                    Yes
                  </a-select-option>
                  <a-select-option value="no">
                    No
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </div>
          <div v-if="selectedCategoriesAssets == 31">
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Software Type</p>
                <a-select class="w-100" placeholder="Pilih UTP Type" v-model:value="dataPayload.softwareType"
                  :disabled="!editable">
                  <a-select-option v-for="( item, index ) in dataSoftwareType " :key="index" :value="item">{{ item
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Vesion</p>
                <a-input placeholder="Masukkan Version" v-model:value="dataPayload.softwareVersion"
                  :disabled="!editable"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24" v-if="selectedCategoriesAssets == 7 ||
          selectedCategoriesAssets == 8 ||
          selectedCategoriesAssets == 10 ||
          selectedCategoriesAssets == 11 ||
          selectedCategoriesAssets == 15 ||
          selectedCategoriesAssets == 17 ||
          selectedCategoriesAssets == 18 ||
          selectedCategoriesAssets == 19 ||
          selectedCategoriesAssets == 20
        ">
          <div>
            <h1 class="text-subtitle">Components</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Name</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Quantity</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Location</p>
                </div>
                <div class="w-100"></div>
              </a-col>
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;"
                v-for="( item, index ) in componentsAssetsData " :key="index">
                <div class="w-100">
                  <a-input placeholder="Masukkan Nama" v-model:value="item.name" :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Quantity" v-model:value="item.quantity"
                    :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Location" v-model:value="item.location"
                    :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-button @click="deleteTroubleshootDetail(item._id)" type="danger" :disabled="!editable || item.id">
                    <DeleteOutlined />
                  </a-button>
                </div>
              </a-col>
              <a-col :span="24">
                <a-button @click="addTroubleshootDetail" :disabled="!editable">
                  <PlusOutlined />
                </a-button>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24" v-if="selectedCategoriesAssets == 25 || selectedCategoriesAssets == 32">
          <div>
            <h1 class="text-title">Hardware Information</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]" class="mb-5">
              <a-col :span="24">
                <h2 class="text-subtitle">Processor</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Manufacturer</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Model</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Speed</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Threads</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Core</p>
                </div>
                <div class="w-100"></div>
              </a-col>
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;"
                v-for="( item, index ) in componentsHardwareProcessor " :key="index">
                <div class="w-100">
                  <a-select placeholder="Pilih Manufacturer" class="w-100" v-model:value="item.manufacturerId"
                    :disabled="!editable">
                    <a-select-option v-for="( itemMan, indexM ) in dataManufatures " :key="indexM"
                      :value="itemMan.id">{{
                        itemMan.name }}</a-select-option>
                  </a-select>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Model" v-model:value="item.model" :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Speed" v-model:value="item.speed" :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Threads" v-model:value="item.threads" :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Core" v-model:value="item.core" :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-button @click="deleteHardwareProcessor(item._id)" type="danger" :disabled="!editable || item.id">
                    <DeleteOutlined />
                  </a-button>
                </div>
              </a-col>
              <a-col :span="24">
                <a-button @click="addHardwareProcessor" :disabled="!editable">
                  <PlusOutlined />
                </a-button>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mb-5">
              <a-col :span="24">
                <h2 class="text-subtitle">Disk Drive</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Manufacturer</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Model</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Serial Number</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Capacity</p>
                </div>
                <div class="w-100"></div>
              </a-col>
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;"
                v-for="( item, index ) in componentsHardwareDiskDrive " :key="index">
                <div class="w-100">
                  <a-select placeholder="Pilih Manufacturer" class="w-100" v-model:value="item.manufacturerId"
                    :disabled="!editable">
                    <a-select-option v-for="( itemMan, indexM ) in dataManufatures " :key="indexM"
                      :value="itemMan.id">{{
                        itemMan.name }}</a-select-option>
                  </a-select>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Model" v-model:value="item.model" :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Serial Number" v-model:value="item.serialNumber"
                    :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <!-- <a-input placeholder="Masukkan Capacity" v-model:value="item.capacity"></a-input> -->
                  <a-select placeholder="Pilih Capacity" class="w-100" v-model:value="item.capacity"
                    :disabled="!editable">
                    <a-select-option v-for="( itemC, indexC ) in capacityOption " :key="indexC" :value="itemC">{{
                      itemC
                    }}</a-select-option>
                  </a-select>
                </div>
                <div class="w-100">
                  <a-button @click="deleteHardwareDiskDrive(item._id)" type="danger" :disabled="!editable || item.id">
                    <DeleteOutlined />
                  </a-button>
                </div>
              </a-col>
              <a-col :span="24">
                <a-button @click="addHardwareDiskDrive" :disabled="!editable">
                  <PlusOutlined />
                </a-button>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mb-5">
              <a-col :span="24">
                <h2 class="text-subtitle">Memory</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Manufacturer</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Model</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Serial Number</p>
                </div>
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Capacity</p>
                </div>
                <div class="w-100"></div>
              </a-col>
              <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;"
                v-for="( item, index ) in componentsHardwareMemory " :key="index">
                <div class="w-100">
                  <a-select placeholder="Pilih Manufacturer" class="w-100" v-model:value="item.manufacturerId"
                    :disabled="!editable">
                    <a-select-option v-for="( itemMan, indexM ) in dataManufatures " :key="indexM"
                      :value="itemMan.id">{{
                        itemMan.name }}</a-select-option>
                  </a-select>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Model" v-model:value="item.model" :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <a-input placeholder="Masukkan Serial Number" v-model:value="item.serialNumber"
                    :disabled="!editable"></a-input>
                </div>
                <div class="w-100">
                  <!-- <a-input placeholder="Masukkan Capacity" v-model:value="item.capacity"></a-input> -->
                  <a-select placeholder="Pilih Capacity" class="w-100" v-model:value="item.capacity"
                    :disabled="!editable">
                    <a-select-option v-for="( itemC, indexC ) in capacityOption " :key="indexC" :value="itemC">{{
                      itemC
                    }}</a-select-option>
                  </a-select>
                </div>
                <div class="w-100">
                  <a-button @click="deleteHardwareMemory(item._id)" type="danger" :disabled="!editable || item.id">
                    <DeleteOutlined />
                  </a-button>
                </div>
              </a-col>
              <a-col :span="24">
                <a-button @click="addHardwareMemory" :disabled="!editable">
                  <PlusOutlined />
                </a-button>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="$router.back()" type="primary">Cancel</a-button>
              <!-- <a-button :disabled="!editable" @click="handleSubmit">Submit</a-button> -->
              <a-button v-if="$route.path.split('/').at(1) !== 'passive'" :disabled="!selectedCategoriesAssets ||
                !dataPayload.name ||
                !dataPayload.manufacturerId ||
                !dataPayload.serialNumber ||
                // Procurement
                // !dataPayload.procurements.purchaseDate ||
                // !dataPayload.procurements.No ||
                // !dataPayload.procurements.value ||
                // !dataPayload.procurements.warranty ||
                // !dataPayload.procurements.warrantyStart ||
                // !dataPayload.procurements.warrantyEnd ||
                // !dataPayload.procurements.vendorId ||
                // // Maintenance

                // !dataPayload.maintenances.purchaseDate ||
                // !dataPayload.maintenances.No ||
                // !dataPayload.maintenances.value ||
                // !dataPayload.maintenances.warranty ||
                // !dataPayload.maintenances.warrantyStart ||
                // !dataPayload.maintenances.warrantyEnd ||
                // !dataPayload.maintenances.vendorId ||
                !editable
                " @click="handleSubmit">Submit</a-button>
              <a-button v-else :disabled="!selectedCategoriesAssets ||
                !dataPayload.name ||
                !dataPayload.manufacturerId ||
                !dataPayload.serialNumber ||
                // Procurement
                // !dataPayload.procurements.purchaseDate ||
                // !dataPayload.procurements.No ||
                // !dataPayload.procurements.value ||
                // !dataPayload.procurements.warranty ||
                // !dataPayload.procurements.warrantyStart ||
                // !dataPayload.procurements.warrantyEnd ||
                // !dataPayload.procurements.vendorId ||
                !editable
                " @click="handleSubmit">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount, watch } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  FormOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification, Form } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import {
  getCustomers,
  getLocationByCustomerId,
  getRoomByCustomerId,
} from '../../services/axios/modc/customer'
import {
  getCategories,
  postAssets,
  getAssetsById,
  editAssetsById,
} from '../../services/axios/modc/assets'
import { getManufactures } from '../../services/axios/modc/manufacture'
import { getVendors } from '../../services/axios/modc/vendor'
import assetsFile from '@/data/modc_dump.json'
import moment from 'moment'
import { uniqueId } from 'lodash'

const columns = [
  {
    title: 'Nama Perangkat',
    dataIndex: 'device',
    key: 'device',
    slots: { customRender: 'device' },
    width: '15%',
  },
  {
    title: 'Nama Brand',
    dataIndex: 'brand',
    key: 'brand',
    slots: { customRender: 'brand' },
  },
  {
    title: 'Nomor Produk',
    dataIndex: 'productNo',
    key: 'productNo',
    slots: { customRender: 'productNo' },
  },
  {
    title: 'Nomor Serial',
    dataIndex: 'serialNo',
    key: 'serialNo',
    slots: { customRender: 'serialNo' },
  },
]
const useForm = Form.useForm
const assetsId = [
  // FACILITIES
  7 /**Elec Pan comps*/,
  8 /**UPS comps*/,
  10 /**Generator Set comps*/,
  11 /**Battery comps*/,
  12 /**Grounding */,
  13 /**Pre AC */,
  14 /**Sta AC */,
  15 /**Fire Supp comps*/,
  16 /**Fire Sup pip */,
  17 /**Fire Ext comps*/,
  18 /**CCTV comps*/,
  19 /**Door comps*/,
  20 /**ENV comps*/,
  // ACTIVE
  22 /** Access Point */,
  23 /** Firewall */,
  24 /** Router */,
  25 /** Server */,
  26 /** Switch */,
  // PASSIVE
  3 /** RACK UNIT */,
  27 /** UTP Cable */,
  28 /** UTP Patch Panel */,
  29 /** FO Patch Panel */,
  30 /** FO Cable */,
]

export default {
  components: { LeftOutlined, PlusOutlined, DeleteOutlined, FormOutlined, EyeOutlined },
  setup() {
    let store = useStore()
    let route = useRoute()
    let router = useRouter()

    const assets = ref()
    // const manufactures = ref()
    const loading = ref(false)
    const catFacility = ref('Access Point')
    const facility = ref('Access-002')
    const selectedTypeofFailure = ref()
    const selectedTypeofSolving = ref()
    const selectedReparation = ref()
    const editable = ref(false)
    const componentsAssetsData = ref([])
    const componentsHardwareProcessor = ref([])
    const componentsHardwareDiskDrive = ref([])
    const componentsHardwareMemory = ref([])
    const options = ref([])
    const value1 = ref('')
    const selectedMaintenanceInformation = ref(0)
    const addMaintenanceInformation = () => {
      dataPayload.value.maintenances.push({
        vendorId: null,
        email: '',
        mobileNumber: null,
        No: null,
        value: null,
        warranty: null,
        warrantyStart: null,
        warrantyEnd: null,
      })
    }


    // CUSTOMER
    const selectedCustomer = ref()
    const dataCustomers = ref([])
    const selectedLocation = ref()
    const dataLocation = ref([])
    const selectedRoom = ref()
    const dataRoom = ref([])

    // CATEGORIES
    const dataCategoriesAssets = ref([])
    const selectedCategoriesAssets = ref()

    // BRAND/MANUFACTURE
    const dataManufatures = ref([])
    const selectedManufatures = ref()

    // VENDORS
    const dataVendors = ref([])
    const selectedVendors = ref()
    const selectedVendors2 = ref()

    const dataTypeofFailure = [
      'Hardware Failure',
      'Software Failure',
      'System Failure',
      'Part Failure',
      'Broken Part',
      'Other',
    ]
    const capacityOption = [
      '64 MB',
      '128 MB',
      '256 MB',
      '512 MB',
      '1 GB',
      '2 GB',
      '4 GB',
      '8 GB',
      '16 GB',
      '32 GB',
      '64 GB',
      '128 GB',
      '256 GB',
      '512 GB',
      '1 TB',
      '2 TB',
      '4 TB',
      '8 TB',
      '16 TB',
      '32 TB',
      '64 TB',
      '128 TB',
      '256 TB',
      '512 TB',
    ]
    const utpType = ['cat3', 'cat4', 'cat5', 'cat5e', 'cat6', 'cat6a', 'cat7', 'cat7a']
    const connectionType = [
      'Point to Point',
      'Point to Multiple Point',
      'Multiple Point to Multiple Point',
      'Switch Hub to Client',
    ]
    const foType = ['OM2', 'OM3', 'OM4', 'Single Mode', 'Multi Mode']
    const foConnectionType = ['Backbone', 'Horizontal', 'Patch Cord']

    const dataSoftwareType = [
      'Antivirus',
      'Database System',
      'Enterprise',
      'Graphics',
      'Operating System',
      'Spreadsheet',
      'Word Processors',
    ]

    const dataTypeofSolving = ['Reparation', 'Part Replacement', 'Reconfiguration']
    const dataReparation = ['Minor', 'Major', 'Overhaul', 'Other']
    const addTroubleshootDetail = () => {
      componentsAssetsData.value.push({
        _id: uniqueId('tbs-'),
        name: '',
        quantity: '',
        location: '',
      })
    }
    const deleteTroubleshootDetail = id => {
      componentsAssetsData.value = componentsAssetsData.value.filter(trb => trb._id !== id)
    }
    const addHardwareProcessor = () => {
      componentsHardwareProcessor.value.push({
        _id: uniqueId('tbs-'),
        manufacturerId: null,
        model: null,
        speed: null,
        threads: null,
        core: null,
      })
    }
    const deleteHardwareProcessor = id => {
      componentsHardwareProcessor.value = componentsHardwareProcessor.value.filter(
        trb => trb._id !== id,
      )
    }
    const addHardwareDiskDrive = () => {
      componentsHardwareDiskDrive.value.push({
        _id: uniqueId('tbs-'),
        manufacturerId: null,
        model: null,
        speed: null,
        threads: null,
        core: null,
      })
    }
    const deleteHardwareDiskDrive = id => {
      componentsHardwareDiskDrive.value = componentsHardwareDiskDrive.value.filter(
        trb => trb._id !== id,
      )
    }
    const role = ref('')
    const addHardwareMemory = () => {
      componentsHardwareMemory.value.push({
        _id: uniqueId('tbs-'),
        manufacturerId: null,
        model: null,
        speed: null,
        threads: null,
        core: null,
      })
    }
    const deleteHardwareMemory = id => {
      componentsHardwareMemory.value = componentsHardwareMemory.value.filter(trb => trb._id !== id)
    }
    onBeforeMount(() => {
      const storedUserData = localStorage.getItem('userData')
      const userData = JSON.parse(storedUserData)
      role.value = userData.user.role
      fetchInitialData()

    })

    const dataPayload = ref({
      wifiName: null,
      availablePort: null,
      workingPort: null,
      deadPort: null,
      ipAdress: null,
      subnetMask: null,
      gateway: null,
      totalPort: null,
      systemName: null,
      hostName: null,
      domainRole: null,
      dns: null,
      os: null,
      softwareName: null,
      softwareVersion: null,
      accessType: null,
      airFlow: null,
      coolingDesign: null,
      coolingType: null,
      coolingCapacity: null,
      inputPowerKw: null,
      panelType: null,
      transferSwitch: null,
      phaseType: null,
      sensorType: null,
      supressType: null,
      fillDate: null,
      expireDate: null,
      extenguisherType: null,
      kva: null,
      kw: null,
      machineType: null,
      systemTrigger: null,
      model: null,
      formFactor: null,
      battery: null,
      height: null,
      width: null,
      depth: null,
      weight: null,
      backupKw: null,
      backupKva: null,
      backupDuration: null,
      inputKw: null,
      inputKva: null,
      outputKw: null,
      outputKva: null,
      batteryType: null,
      voltage: null,
      cells: null,
      controllerStatus: null,
      utpType: null,
      node: null,
      core: null,
      connectionType: null,
      patch: null,
      cableManagement: null,
      rackUnit: null,
      crossConnection: null,
      foType: null,
      softwareType: null,
      version: null,
      customerId: null,
      locationId: null,
      roomId: null,
      assetSubCategoryId: null,
      manufacturerId: null,
      vendorId: null,
      name: null,
      modelAsset: null,
      productNumber: null,
      serialNumber: null,
      serviceTag: null,
      assetTag: null,
      rackNumber: null,
      uNumber: null,
      uSize: null,
      status: null,
      diskDrives: [
        // {
        //   model: 'Disk Drive Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      memories: [
        // {
        //   model: 'Memory Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      processors: [
        // {
        //   model: 'Processor Model 1',
        //   speed: '3.6 GHz',
        //   threads: 6,
        //   cores: 6,
        // },
      ],
      components: [
        // {
        //   model: 'Processor Model 1',
        //   speed: '3.6 GHz',
        //   threads: 6,
        //   cores: 6,
        // },
      ],
      procurements: {
        vendorId: null,
        email: null,
        mobileNumber: null,
        No: null,
        value: null,
        warranty: null,
        warrantyStart: null,
        warrantyEnd: null,
      },
      maintenances: [{
        vendorId: null,
        email: null,
        mobileNumber: null,
        No: null,
        value: null,
        warranty: null,
        warrantyStart: null,
        warrantyEnd: null,
      }],
    })
    const rulesPayload = ref({
      wifiName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      availablePort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      workingPort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      deadPort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      ipAdress: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      subnetMask: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      gateway: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      totalPort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      systemName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      hostName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      domainRole: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      dns: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      os: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      softwareName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      softwareVersion: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      accessType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      airFlow: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      coolingDesign: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      coolingType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      coolingCapacity: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      inputPowerKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      panelType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      transferSwitch: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      phaseType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      sensorType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      supressType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      fillDate: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      expireDate: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      extenguisherType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      kva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      kw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      machineType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      systemTrigger: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      model: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      formFactor: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      battery: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      height: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      width: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      depth: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      weight: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      backupKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      backupKva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      backupDuration: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      inputKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      inputKva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      outputKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      outputKva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      batteryType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      voltage: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      cells: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      controllerStatus: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      utpType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      node: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      core: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      connectionType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      patch: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      cableManagement: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      rackUnit: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      crossConnection: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      foType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      softwareType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      version: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      customerId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      locationId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      roomId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      assetSubCategoryId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      manufacturerId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      vendorId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      name: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      modelAsset: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      productNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      serialNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      serviceTag: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      assetTag: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      rackNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      uNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      uSize: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      status: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      diskDrives: [
        // {
        //   model: 'Disk Drive Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      memories: [
        // {
        //   model: 'Memory Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      processors: [
        // {
        //   model: 'Processor Model 1',
        //   speed: '3.6 GHz',
        //   threads: 6,
        //   cores: 6,
        // },
      ],
      components: [
        // {
        //   model: 'Processor Model 1',
        //   speed: '3.6 GHz',
        //   threads: 6,
        //   cores: 6,
        // },
      ],
      procurements: {
        vendorId: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        mobileNumber: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        No: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        Date: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        value: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warranty: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyStart: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyEnd: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
      },
      maintenances: {
        vendorId: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        mobileNumber: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        No: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        Date: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        value: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warranty: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyStart: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyEnd: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
      },
    })
    watch(
      [
        () => dataPayload.value.procurements,
        () => dataPayload.value.maintenances[selectedMaintenanceInformation.value],
      ],
      ([newProcurements, newMaintenances], [oldProcurements, oldMaintenances]) => {
        console.log('Perubahan terdeteksi pada procurements atau maintenances');

        // Logika untuk procurements
        if (moment.isMoment(newProcurements.warrantyStart)) {
          let newWarrantyEnd = newProcurements.warrantyStart.clone().add(newProcurements.warranty, 'months');
          if (!newWarrantyEnd.isSame(oldProcurements.warrantyEnd)) {
            newProcurements.warrantyEnd = newWarrantyEnd;
            console.log('Procurements warrantyEnd updated:', newWarrantyEnd);
          }
        } else {
          console.log("Procurements warrantyStart bukan objek Moment");
        }

        // Logika untuk maintenances
        if (newMaintenances && moment.isMoment(newMaintenances.warrantyStart)) {
          let newWarrantyEnd = newMaintenances.warrantyStart.clone().add(newMaintenances.warranty, 'months');
          if (!newWarrantyEnd.isSame(oldMaintenances ? oldMaintenances.warrantyEnd : null)) {
            newMaintenances.warrantyEnd = newWarrantyEnd;
            console.log('Maintenances warrantyEnd updated:', newWarrantyEnd);
          }
        } else {
          console.log("Maintenances warrantyStart bukan objek Moment atau data tidak ada");
        }
      },
      { deep: true },
    );


    // const { resetFields, validate, validateInfos } = useForm(dataPayload, rulesPayload)

    const fetchInitialData = async () => {
      loading.value = true
      const customers = await getCustomers()
      dataCustomers.value = customers.customer
      const categories = await getCategories()
      let catAssets
      switch (route.path.split('/').at(1)) {
        case 'active':
          catAssets = 1
          break
        case 'passive':
          catAssets = 2
          break
        case 'facilities':
          catAssets = 3
          break
        case 'software':
          catAssets = 4
          break
        case 'infra':
          catAssets = 5
          break
        case 'monitoring':
          catAssets = 6
          break

        default:
          break
      }
      dataCategoriesAssets.value = categories.categories.filter(
        cat => cat.assetCategoryId == catAssets,
      )
      const currentRoute = route.path
        .split('/')
        .at(-2)
        .split('_')
        .join(' ')
        .split(' ')
      let strRouteCapital = ''
      currentRoute.forEach(txt => {
        strRouteCapital += txt.split('')[0].toUpperCase() + txt.slice(1) + ' '
      })

      const manufactures = await getManufactures()
      dataManufatures.value = manufactures.manufacturers

      const vendors = await getVendors()
      dataVendors.value = vendors.vendor
      const { assets } = await getAssetsById(route.params.id)
      const {
        AssetSubCategory,
        Customer,
        HardwareInformation,
        Location,
        Manufacturer,
        Room,
        TechnicalActiveDevice,
        TechnicalFacility,
        TechnicalPassiveDevice,
        TechnicalSoftware,
        ProcurementInformation,
        MaintenanceInformations,
        Components,
        ...rest
      } = assets
      // console.log(
      //   AssetSubCategory,
      //   Customer,
      //   HardwareInformation,
      //   Location,
      //   Manufacturer,
      //   Room,
      //   TechnicalActiveDevice,
      //   TechnicalFacility,
      //   TechnicalPassiveDevice,
      //   TechnicalSoftware,
      //   Components,
      //   '<<<<<CATEGORIES',
      // )
      console.log(MaintenanceInformations, 'yang inkah')
      selectedCustomer.value = Customer ? Customer.id : null
      handleChangeCustomer(Customer ? Customer.id : null)
      selectedLocation.value = Location ? Location.id : null
      handleChangeLocation(Location ? Location.id : null)
      selectedRoom.value = Room ? Room.id : null
      selectedCategoriesAssets.value = AssetSubCategory ? AssetSubCategory.id : null
      selectedManufatures.value = Manufacturer ? Manufacturer.id : null
      componentsAssetsData.value = Components
      dataPayload.value = {
        ...dataPayload.value,
        customerId: String(Customer ? Customer.id : null),
        locationId: String(Location ? Location.id : null),
        roomId: String(Room ? Room.id : null),
        assetSubCategoryId: String(AssetSubCategory ? AssetSubCategory.id : null),
        name: rest.name,
        manufacturerId: Manufacturer ? Manufacturer.id : null,
        modelAsset: rest.model,
        productNumber: rest.productNumber,
        serialNumber: rest.serialNumber,
        serviceTag: rest.serviceTag,
        assetTag: rest.assetTag,
        rackNumber: rest.rackNumber,
        uNumber: rest.uNumber,
        uSize: rest.uSize,
        procurements: {
          ...dataPayload.value.procurements,
          ...ProcurementInformation,
        },
        maintenances: MaintenanceInformations ? MaintenanceInformations : [{
          vendorId: null,
          email: null,
          mobileNumber: null,
          No: null,
          Date: null,
          value: null,
          warranty: null,
          warrantyStart: null,
          warrantyEnd: null,
        }],



        ...TechnicalActiveDevice,
        ...TechnicalFacility,
        ...TechnicalPassiveDevice,
        ...TechnicalSoftware,
        ...Components,
        cableManagement: TechnicalPassiveDevice?.cableManagement ? 'yes' : 'no',
        crossConnection: TechnicalPassiveDevice?.crossConnection ? 'yes' : 'no',
      }
      console.log(dataPayload.value)
      loading.value = false
    }
    const handleChangeCustomer = async e => {
      const location = await getLocationByCustomerId(e)
      dataLocation.value = location.locations
      dataPayload.value.customerId = e
    }
    const handleChangeLocation = async e => {
      const room = await getRoomByCustomerId(selectedCustomer.value, e)
      // console.log(room)
      dataRoom.value = room.rooms
      dataPayload.value.locationId = e
    }
    const handleChangeRoom = async e => {
      dataPayload.value.roomId = e
    }
    const handleChangeCategoriesAssets = async e => {
      dataPayload.value.assetSubCategoryId = e
    }
    const handleChangeProcurementVendor = async e => {
      const vendorProcurement = dataVendors.value.find(data => data.id == e)
      dataPayload.value.procurements.email = vendorProcurement.email
      dataPayload.value.procurements.mobileNumber = vendorProcurement.mobileNumber
    }
    const handleChangeMaintenancesVendor = async e => {
      const vendorMaintenance = dataVendors.value.find(data => data.id == e)
      console.log(selectedMaintenanceInformation.value)
      console.log(dataPayload.value.maintenances)
      dataPayload.value.maintenances[selectedMaintenanceInformation.value].email = vendorMaintenance.email
      dataPayload.value.maintenances[selectedMaintenanceInformation.value].mobileNumber = vendorMaintenance.mobileNumber
    }

    const changeEdittable = () => {
      loading.value = true
      editable.value = !editable.value
      loading.value = false
    }
    const handleSubmit = async () => {
      dataPayload.value.components = componentsAssetsData.value
      const payload = dataPayload.value
      // console.log(validateInfos, '<<<data')
      // validate()
      //   .then(() => {
      //     console.log(dataPayload, '<<<<PAYLOAD')
      //   })
      //   .catch(err => {
      //     console.log(err, '<<<<<<<<<<ERR')
      //   })
      const routeCat = dataCategoriesAssets.value.find(
        val => val.id == selectedCategoriesAssets.value,
      )
      let routeName
      switch (routeCat.name) {
        case 'Electrical Panel':
          routeName = 'electrical_panel'
          break
        case 'UPS':
          routeName = 'ups'
          break
        case 'Generator Set':
          routeName = 'generator_set'
          break
        case 'Battery':
          routeName = 'battery'
          break
        case 'Precision AC':
          routeName = 'precision_ac'
          break
        case 'Standard AC':
          routeName = 'standard_ac'
          break
        case 'Fire Suppr. Gas & Cylinder':
          routeName = 'fire_suppresion'
          break
        case 'Fire Suppr. Piping':
          routeName = 'fire_piping'
          break
        case 'Fire Extinguisher':
          routeName = 'fire_extinguisher'
          break
        case 'Security - CCTV':
          routeName = 'cctv'
          break
        case 'Security - Access Door':
          routeName = 'access_door'
          break
        case 'Environment & Facility':
          routeName = 'env_monitoring'
          break
        case 'Access Point':
          routeName = 'access_point'
          break
        case 'Firewall':
          routeName = 'firewall'
          break
        case 'Router':
          routeName = 'router'
          break
        case 'Server':
          routeName = 'serve'
          break
        case 'Switch':
          routeName = 'switch'
          break
        case 'Storage':
          routeName = 'storage'
          break
        case 'UTP Cable':
          routeName = 'utp_cable'
          break
        case 'UTP Patch Panel':
          routeName = 'utp_patch_panel'
          break
        case 'FO Patch Panel':
          routeName = 'fo_patch_panel'
          break
        case 'Rack Unit':
          routeName = 'rack'
          break
        case 'Software':
          routeName = 'list'
          break

        default:
          break
      }
      console.log(payload, 'iniwaktuupdate')
      const res = await editAssetsById(route.params.id, payload)
      if (res) {
        notification.success({
          message: 'Berhasil Update',
          description: 'Data Asset Berhasil Diupdate',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }
      router.push('/' + route.path.split('/').at(1) + '/' + routeName)
      console.log(res, '<<<<PAYLOAD')
    }
    // const options2 = ref(['data 1', 'data 2'])
    return {
      role,
      addMaintenanceInformation,
      selectedMaintenanceInformation,
      options,
      // options2,
      value1,
      // assetsFile,
      // assets,
      // getAssets,
      // getManufacture,
      // manufactures,
      loading,
      columns,
      // detail troubleshoot
      facility,
      catFacility,
      componentsAssetsData,
      componentsHardwareProcessor,
      componentsHardwareDiskDrive,
      componentsHardwareMemory,
      // Last Section
      dataTypeofFailure,
      dataTypeofSolving,
      dataReparation,
      selectedReparation,
      selectedTypeofFailure,
      selectedTypeofSolving,
      editable,

      // getCurrentDate,
      changeEdittable,

      addTroubleshootDetail,
      deleteTroubleshootDetail,
      addHardwareProcessor,
      deleteHardwareProcessor,
      addHardwareDiskDrive,
      deleteHardwareDiskDrive,
      addHardwareMemory,
      deleteHardwareMemory,
      handleSubmit,

      // DATA CUSTOMER
      selectedCustomer,
      dataCustomers,
      selectedLocation,
      dataLocation,
      selectedRoom,
      dataRoom,
      // FUNCTION
      handleChangeCustomer,
      handleChangeLocation,
      handleChangeRoom,
      handleChangeCategoriesAssets,
      handleChangeProcurementVendor,
      handleChangeMaintenancesVendor,
      // DATA CUSTOMER

      // CATEGORY ASSETS
      dataCategoriesAssets,
      selectedCategoriesAssets,
      // CATEGORY ASSETS

      // MANUFACTURE
      dataManufatures,
      selectedManufatures,
      // MANUFACTURE

      // VENDORS
      dataVendors,
      selectedVendors,
      selectedVendors2,
      // VENDORS

      // CAPACITY
      capacityOption,
      // CAPACITY

      // PASSIVE
      utpType,
      connectionType,
      foType,
      foConnectionType,
      // PASSIVE

      // SOFTWARE
      dataSoftwareType,
      // SOFTWARE

      // DATA PAYLOAD
      dataPayload,
      // validateInfos,
      // DATA PAYLOAD
      // dataSource,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
